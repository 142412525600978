













































import { Vue, Component, Prop } from "vue-property-decorator";
import { CustomerPaymentCustomerProperties } from "./CustomerPaymentCustomerView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import EventManager from "@/utilities/EventManager";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import CustomerPaymentService from "@/services/CustomerPaymentService";

class CustomerPaymentCreateDialogProperties {
    visible: boolean = false;
    events = new EventManager();
}

export { CustomerPaymentCreateDialogProperties };

@Component({
    data: () => ({
        customer: {},
        type: "Checkout",
        loading: false,
        saving: false,
    }),
})
export default class CustomerPaymentCreateDialogView extends Vue {
    @Prop({ default: new CustomerPaymentCreateDialogProperties() }) 
    private properties: CustomerPaymentCreateDialogProperties;
    @Prop() private customerPaymentCustomer: CustomerPaymentCustomerProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private customerPaymentService = new CustomerPaymentService();

    public get title() {
        const t = this.$t('title.customer-payment');
        return t + ' - ' + this.$t('title.create');
    }

    public get typeOptions() {
        return [
            {
                text: this.$t('text.checkout'),
                value: "Checkout"
            },
            {
                text: this.$t('text.deposit'),
                value: "Deposit"
            }
        ];
    }

    public created() {
        this.customerPaymentCustomer.events.subscribe('selected', this.selectedCustomer);
    }

    public destroyed() {
        this.customerPaymentCustomer.events.remove('selected', this.selectedCustomer);        
    }

    public async submit() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            const customerPayment = {
                customerId: this.$data.customer?.id,
                type: this.$data.type,
                deliveryMethod: null,
                paymentMethod: null,
                status: "Draft"
            };
            const r = await this.customerPaymentService.post(customerPayment);

            await this.properties.events.fire("created", r.data);
            this.properties.visible = false;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public cancel() {
        if (this.$data.saving) {
            return;
        }
        this.properties.visible = false;
    }

    public selectCustomer() {
        this.customerPaymentCustomer.tag = "create";
        this.customerPaymentCustomer.visible = true;
    }

    public selectedCustomer(customer: any) {
        const tag = this.customerPaymentCustomer.tag;
        if (tag === "create") {
            customer.text = `${customer.code} - ${customer.name}`;
            this.$data.customer = customer;
            this.customerPaymentCustomer.visible = false;
        }
    }

    public customerCleared() {
        this.$data.customer = {};
    }
}
