












































































import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from '@/components/shared/AlertDialogView.vue';
import CustomerService from '@/services/CustomerService';
import ExceptionUtil from '@/utilities/ExceptionUtil';
import EventManager from '@/utilities/EventManager';

class CustomerPaymentCustomerProperties {
    visible: boolean = false;
    events = new EventManager();
    tag: any = null;
}

export { CustomerPaymentCustomerProperties };

@Component({
    data: () => ({
        table: {
            recordCounts: [10, 20, 30, 40, 50],
            loading: false,
            pageNo: 1,
            pageCount: 1,
            recordCount: 20,
        },
        filter: {
            code: "",
            name: ""
        },
        customers: []
    })
})
export default class CustomerPaymentCustomerView extends Vue {
    @Prop({ default: new CustomerPaymentCustomerProperties() })
    private properties: CustomerPaymentCustomerProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private customerService = new CustomerService();
    private queueHandle: number = 0;    

    public get headers() {
        return [
            {
                text: this.$t('text.code'),
                value: "code",
                width: "25%"
            },
            {
                text: this.$t('text.name'),
                value: "name"
            },
            {
                text: "",
                value: "action",
                fixed: true,
                align: "center",
                width: "120px",
                sortable: false,
            },
        ];
    }

    public created() {
        this.load();
    }

    public loadQueue(delay: number = 500) {
        if (this.queueHandle !== 0) {
            clearTimeout(this.queueHandle);
        }

        this.queueHandle = setTimeout(() => {
            this.queueHandle = 0;
            this.load();
        }, delay);
    }

    public async load() {
        const pageNo = this.$data.table.pageNo;
        const rowCount = this.$data.table.recordCount;
        const rowStart = (pageNo - 1) * rowCount;

        this.$data.table.loading = true;

        try {
            const filter = this.$data.filter;
            const r = await this.customerService.get({
                rowStart: rowStart,
                rowCount: rowCount,
                countRecord: true,
                hidden: false
            });

            var count = Math.ceil(r.data.count / rowCount);
            if (count < 1) count = 1;

            const customers = r.data.customers;
            this.$data.customers = customers;

            await Vue.nextTick();
            this.$data.table.pageCount = count;
            if (pageNo > count) {
                this.$data.table.pageNo = count;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.table.loading = false;
        }
    }

    public async selected(item: any) {
        await this.properties.events.fire('selected', item);
    }

    public close() {
        this.properties.visible = false;
    }
}
